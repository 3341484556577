html {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
}
body {
  margin: 0;
  padding: 0 0 0 10px;
  background: transparent;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
}

.text-center {
  text-align: center !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}
.sticky-container {
  overflow-y: auto;
}
